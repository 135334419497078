import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import Footer from 'components/Footer'
import Calendly from 'components/Calendly'

const Demo = () => {
  return (
    <App>
      <SEO
        title="Demo Request | Vista Social"
        description="Vista Social provides social media management tools for small business, agency and enterprise. Sign up for a demo."
        path="/demo/"
      />
      <Flex mt="xl" px="m" alignItems="center" flexDirection="column" textAlign="center">
        <Box width="100%" minHeight="920px">
          <Calendly link="https://calendly.com/d/4d7-x9f-r7z" />
        </Box>
      </Flex>

      <Footer />
    </App>
  )
}

export default Demo
